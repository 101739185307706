<template>
    <div>
        <graduate-application-layout>
            <slot></slot>
            <div class="row vh-100 overflow-x-hidden">
                <div class="col-6 pl-0 bg-cover-center ml-minus-5px d-none d-lg-block p-0 login-right-img"
                     v-bind:style="{'background-image':'url(' + require('@/assets/img/login-right-bg.jpg')+')'}">
                    <div class="login-text">
                        {{ $t('graduate_application_form').toUpper() }}
                    </div>
                </div>
                <div class="col-12 col-lg-6 bg-white rounded-lg mr-0 mr-md-minus-5px z-index-1 shadow">
                    <div class="d-flex align-items-start flex-column h-100">
                        <div class="my-auto w-100">
                            <div class="row justify-content-center">
                                <div class="col-12 mb-4 d-flex justify-content-center">
                                    <div class="logo-wrapper"><img src="../../../../assets/img/bau-logo-for-light.svg"/></div>
                                </div>
                                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">
                                    <ValidationObserver ref="form">
                                        <div class="mx-auto">
                                            <ValidationProvider name="national_id" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('national_id') + ' / ' + $t('passport_number')">
                                                    <b-form-input type="text" v-model="forgotForm.national_id"
                                                                  :state="errors[0] ? false : null" />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="telephone" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('telephone')">
                                                    <phone-number-input v-model="forgotForm.phone" :only-countries="['TR']"
                                                                        :no-country-selector="true" :no-example="true"
                                                                        :validateError="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="email" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('email')">
                                                    <b-form-input v-model="forgotForm.email"
                                                                  type="email"
                                                                  :state="errors[0] ? false : null" />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                            <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" size="invisible"
                                                           sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG" />
                                            <div class="d-flex justify-content-center my-4">
                                                <b-button variant="primary" block @click="forgot()">
                                                    {{ $t('send').toUpper() }}
                                                </b-button>
                                            </div>
                                            <a class="text-center d-block text-muted cursor-pointer" @click="goToLogin()">
                                                {{ $t('login') }}
                                            </a>
                                        </div>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </graduate-application-layout>
    </div>
</template>
<script>
// Layout
import GraduateApplicationLayout from "@/layouts/GraduateApplicationLayout";
// Service
import GraduateApplicationService from "@/services/GraduateApplicationService";

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { VueRecaptcha } from 'vue-recaptcha'
import PhoneNumberInput from "@/components/elements/PhoneNumberInput.vue";

export default {
    name: "GraduateForgotPassword",
    components: {
        PhoneNumberInput,
        GraduateApplicationLayout,
        ValidationObserver,
        ValidationProvider,
        VueRecaptcha
    },
    data() {
        return {
            forgotForm: {
                national_id: null,
                phone: null,
                email: null,
            }
        }
    },
    methods: {
        async forgot() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.$refs.recaptcha.execute()
            }
        },
        onCaptchaVerified(recaptchaToken) {
            this.forgotForm.g_recaptcha_token = recaptchaToken;
            GraduateApplicationService.pinReminder(this.forgotForm)
                .then(response => {
                    if (response.data.success) {
                        this.$toast.success(this.$t('password_send_success'));
                        this.$router.push('/graduate/application/login');
                    }
                })
                .catch((e) => {
                    this.showErrors(e);
                }).finally(() => {
                this.$refs.recaptcha.reset()
            })
        },
        goToLogin() {
            this.$router.push('/graduate/application/login');
        }
    }
}
</script>
